import { Routes } from "@angular/router";
import { webRoutes } from "./core/constants/routes";
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { HomeComponent } from "./pages/home/home.component";
import { SignUpAIComponent } from "./pages/signup-ai/signup-ai.component";
import { ProfileComponent } from "./pages/profile/profile.component";
// import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { ServicesComponent } from "./pages/services/services.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { PreregisterMarvComponent } from "./pages/preregister-marv/preregister-marv.component";
import { PreregisterCoComponent } from "./pages/preregister-co/preregister-co.component";
import { authGuard } from "./shared/guards/auth.guard";
import { VerifyComponent } from "./pages/verify/verify.component";
// import { QuestionnaireComponent } from "./questionnaire/questionnaire.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: webRoutes.home,
    pathMatch: "full",
  },
  {
    path: webRoutes.signup,
    component: SignUpAIComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/signup-ai/signup-ai.component").then(
            (m) => m.SignUpAIComponent
          ),
        data: { title: "SignUp" },
      },
    ],
  },
  // home
  {
    path: webRoutes.home,
    component: HomeComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/home/home.component").then((m) => m.HomeComponent),
        data: { title: "home" },
      },
    ],
  },
  {
    path: webRoutes.services,
    component: ServicesComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/services/services.component").then(
            (m) => m.ServicesComponent
          ),
        data: { title: "Services" },
      },
    ],
  },
  {
    path: webRoutes.contact,
    component: ContactComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/contact/contact.component").then(
            (m) => m.ContactComponent
          ),
        data: { title: "Contact-Us" },
      },
    ],
  },
  {
    path: webRoutes.policy,
    children: [
      {
        path: "privacy-policy",
        loadComponent: () =>
          import("./pages/policy/privacy-policy/privacy-policy.component").then(
            (m) => m.PrivacyPolicyComponent
          ),
        data: { title: "Privacy Policy" },
      },
      {
        path: "terms-and-conditions",
        loadComponent: () =>
          import(
            "./pages/policy/terms-and-conditions/terms-and-conditions.component"
          ).then((m) => m.TermsAndConditionsComponent),
        data: { title: "Terms and Conditions" },
      },
      {
        path: "cookies-policy",
        loadComponent: () =>
          import("./pages/policy/cookies-policy/cookies-policy.component").then(
            (m) => m.CookiesPolicyComponent
          ),
        data: { title: "Cookie Policy" },
      },
    ],
  },
  {
    path: "explore",
    loadComponent: () =>
      import("./pages/explore/explore.component").then(
        (m) => m.ExploreComponent
      ),  
    data: { title: "Explore" },
  },
  // Auth
  {
    path: webRoutes.login,
    component: AuthComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/login/login.component").then((m) => m.LoginComponent),
        data: { title: "Login" },
      },
    ],
  },
  {
    path: `${webRoutes.verify}/:token`,
    loadComponent: () =>
      import("./pages/verify/verify.component").then((c) => c.VerifyComponent),
    data: { title: "Verify" },
  },
  {
    path: webRoutes.forgotPassword,
    loadComponent: () =>
      import("./pages/forgot-password/forgot-password.component").then(
        (m) => m.ForgotPasswordComponent
      ),
    data: { title: "Forgot Password" },
  },
  {
    path: `${webRoutes.resetPassword}/:token`,
    loadComponent: () =>
      import("./pages/reset-password/reset-password.component").then(
        (m) => m.ResetPasswordComponent
      ),
    data: { title: "Reset Password" },
  },
  {
    path: webRoutes.register,
    loadComponent: () =>
      import("./pages/register/register.component").then(
        (m) => m.RegisterComponent
      ),
    data: { title: "Register" },
  },
  {
    path: webRoutes.preregisterMarv,
    loadComponent: () =>
      import("./pages/preregister-marv/preregister-marv.component").then(
        (m) => m.PreregisterMarvComponent
      ),
    data: { title: "Marveler" },
  },
  {
    path: webRoutes.preregisterCo,
    loadComponent: () =>
      import("./pages/preregister-co/preregister-co.component").then(
        (m) => m.PreregisterCoComponent
      ),
    data: { title: "Counselor" },
  },
  {
    path: webRoutes.pressrelease,
    loadComponent: () =>
      import("./pages/press-release/press-release.component").then(
        (m) => m.PressReleaseComponent
      ),
    data: { title: "Press Release" },
  },
  {
    path: webRoutes.matchingTest,
    loadComponent: () =>
      import("./pages/matching-test/matching-test.component").then(
        (m) => m.MatchingTestComponent
      ),
    data: { title: "Matching Algo. Test" },
  },
  {
    path: webRoutes.profile,
    component: ProfileComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/profile/profile.component").then(
            (m) => m.ProfileComponent
          ),
        data: { title: "User profile" },
      },
    ],
  },
  {
    path: webRoutes.profileNew,
    // component: ProfileNewComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/profilenew/profilenew.component").then(
            (m) => m.ProfileNewComponent
          ),
        data: { title: "User profile" },
      },
    ],
  },
  {
    path: webRoutes.comingSoon,
    component: ComingSoonComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./components/coming-soon/coming-soon.component").then(
            (m) => m.ComingSoonComponent
          ),
        data: { title: "Coming soon" },
      },
    ],
  },
  // Dashboards
  {
    path: "dashboard",
    component: AdminComponent,
    children: [
      {
        path: webRoutes.marveler,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/dashboard-marveler/dashboard-marveler.component"
          ).then((m) => m.DashboardMarvelorComponent),
          data: { title: "Dashboard Marveler" },
      },
      {
        path: webRoutes.counselor,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/dashboard-counselor/dashboard-counselor.component"
          ).then((m) => m.DashboardCounselorComponent),
          data: { title: "Dashboard Counselor"},
      },
      {
        path: webRoutes.explorer,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/explorer/explorer.component"
          ).then((m) => m.ExplorerComponent),
        data: { title: "Explorer" },
      },
      {
        path: webRoutes.profileMarveler,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/profile-marveler/profile-marveler.component"
          ).then((m) => m.ProfileMarvelerComponent),
        data: { title: "Profile Marveler" },
      },
      {
        path: webRoutes.profileCounselor,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/profile-counselor/profile-counselor.component"
          ).then((m) => m.ProfileCounselorComponent),
        data: { title: "Profile counselor" },
      },
      {
        path: webRoutes.editMarveler,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/edit-marveler/edit-marveler.component"
          ).then((m) => m.EditMarvelerComponent),
        data: { title: "Edit Info" },
      },
      {
        path: webRoutes.gallery,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/edit-marveler/gallery/gallery.component"
          ).then((c) => c.GalleryComponent),
        data: { title: "Edit Info" },
      },
      {
        path: webRoutes.marvelerInformations,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/edit-marveler/informations/informations.component"
          ).then((m) => m.InformationsComponent),
        data: { title: "Marveler Informations", userType: 'marveler' },
      },
      {
        path: webRoutes.editCounselor,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/edit-counselor/edit-counselor.component"
          ).then((m) => m.EditCounselorComponent),
        data: { title: "Edit Info", userType: 'counselor' },
      },
      {
        path: webRoutes.counselorInformations,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/edit-counselor/informations/informations.component"
          ).then((m) => m.InformationsComponent),
        data: { title: "Counselor Informations" },
      },
      {
        path: webRoutes.booking,
        loadComponent: () =>
          import("./pages/dashboard/components/booking/booking.component").then(
            (m) => m.BookingComponent
          ),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.availability,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/availability/availability.component"
          ).then((m) => m.AvailabilityComponent),
        data: { title: "Availability" },
      },
      {
        path: webRoutes.counselorProfile,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/counselor-profile/counselor-profile.component"
          ).then((m) => m.CounselorProfileComponent),
        data: { title: "Availability" },
      },
      {
        path: webRoutes.checkout,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/checkout/checkout.component"
          ).then((m) => m.CheckoutComponent),
        data: { title: "Availability" },
      },
      {
        path: webRoutes.payment,
        loadComponent: () =>
          import("./pages/dashboard/components/payment/payment.component").then(
            (m) => m.PaymentComponent
          ),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.earning,
        loadComponent: () =>
          import("./pages/dashboard/components/earning/earning.component").then(
            (m) => m.EarningComponent
          ),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.counselors,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/counselors/counselors.component"
          ).then((m) => m.CounselorsComponent),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.reviews,
        loadComponent: () =>
          import("./pages/dashboard/components/reviews/reviews.component").then(
            (m) => m.ReviewsComponent
          ),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.setting,
        loadComponent: () =>
          import("./pages/dashboard/components/setting/setting.component").then(
            (m) => m.SettingComponent
          ),
        data: { title: "Setting" },
      },
      {
        path: webRoutes.updatePassword,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/setting/update-password/update-password.component"
          ).then((m) => m.UpdatePasswordComponent),
        data: { title: "Update Password" },
      },
      {
        path: webRoutes.unsubscribe,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/setting/unsubscribe/unsubscribe.component"
          ).then((m) => m.UnsubscribeComponent),
        data: { title: "Update Password" },
      },
      {
        path: webRoutes.policies,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/setting/policies/policies.component"
          ).then((m) => m.PoliciesComponent),
        data: { title: "Update Password" },
      },
      {
        path: webRoutes.updateAuth,
        loadComponent: () =>
          import(
            "./pages/dashboard/components/setting/update-password/update-auth/update-auth.component"
          ).then((m) => m.UpdateAuthComponent),
        data: { title: "Update Auth" },
      },
      // Adsync
      {
        path: webRoutes.adsync,
        loadComponent: () =>
          import("./pages/adsync/adsync.component").then(
            (m) => m.AdsyncComponent
          ),
        data: { title: "Adsync Express" },

        children: [
          {
            path: "",
            loadComponent: () =>
              import(
                "./pages/adsync/components/listing/listing.component"
              ).then((m) => m.ListingComponent),
            data: { title: "Adsync Express" },
          },
          {
            path: webRoutes.create,
            loadComponent: () =>
              import("./pages/adsync/components/form/form.component").then(
                (m) => m.FormComponent
              ),
            data: { title: "Create Adsync Express" },
          },
        ],
      },
      // Billboard
      {
        path: webRoutes.billboards,
        loadComponent: () =>
          import("./pages/billboards/billboards.component").then(
            (m) => m.BillboardsComponent
          ),
        data: { title: "Billboards" },

        children: [
          {
            path: "",
            loadComponent: () =>
              import(
                "./pages/billboards/components/listing/listing.component"
              ).then((m) => m.ListingComponent),
            data: { title: "Billboards" },
          },
          {
            path: webRoutes.create,
            loadComponent: () =>
              import("./pages/billboards/components/form/form.component").then(
                (m) => m.FormComponent
              ),
            data: { title: "Create Billboard" },
          },
        ],
      },
    ],
    canActivate: [authGuard]
  },
  {
    path: "**",
    redirectTo: webRoutes.comingSoon,
    pathMatch: "full",
  },
];
